import React from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import classNames from "classnames"
import { Map, fromJS } from "immutable"
import ProductTile from "highline/components/category/product_tile"
import { getDynamicProps } from "highline/utils/category_hybrid_props_helper"
import styles from "highline/styles/components/contentful_product_tile.module.css"
import { useViewportSize } from "highline/hooks/use_viewport_size"

function ContentfulProductTile({
  color,
  contentfulId,
  handleProductTileClick,
  handleQuickAddClick,
  handleSwatchClick,
  handleSwatchMouseEnter,
  handleSwatchMouseLeave,
  itemsDetails,
  primaryImageOverride,
  secondaryImageOverride,
  sku,
  shouldShowPrices,
  shouldShowSwatches,
  placeholder,
  promo,
  priority,
  promotionExclusions,
  className,
  hideTitleAndFit,
  shouldShowProductDescription,
  descriptionOverride,
  activeSitewidePromoDiscount,
}) {
  const { isSmartphone, isTablet } = useViewportSize()

  const productKey = `${sku}-${color}`

  function renderProductTile() {
    const productKey = `${sku}-${color}`
    const itemDetail = itemsDetails.get(productKey)
    const id = itemDetail.get("id")
    const slug = itemDetail.get("programSlug")
    const inStock = itemDetail.get("inStock")
    const itemName = itemDetail.get("name")
    const selectedFilters = Map() // When filters are added to Contentful page this will need to be updated
    const isFielderProduct = itemDetail.get("isFielderProduct")
    const isGiftCard = itemDetail.get("isGiftCard")
    const isBundle = itemDetail.get("isBundle")
    const swatches = itemDetail.get("swatches")
    const hoveredIndex = itemDetail.get("activatedSwatchIndex")
    const selectedIndex = itemDetail.get("selectedSwatchIndex")
    let selectedSwatchIndex = 0
    if (hoveredIndex != null) {
      selectedSwatchIndex = hoveredIndex
    } else {
      selectedSwatchIndex = selectedIndex
    }

    const numberOfFits = !hideTitleAndFit ? itemDetail.get("numberOfFits") : 1
    const countDetails = numberOfFits > 1 ? `${numberOfFits} Fits` : ""
    const swatch = swatches.get(selectedSwatchIndex)
    const {
      fullPrice,
      price,
      primaryImageUrl,
      secondaryImageUrl,
      link,
      selectedOptions,
      ariaLabel,
      title,
    } = getDynamicProps(
      swatch ? swatch : itemDetail,
      slug,
      selectedFilters,
      isBundle,
      isGiftCard,
      itemName
    )

    /*
     * The following flags determine if swatches are shown in the product tile or not.
     * shouldShowSwatches is chosen by a contentful editor. If its a bundle or has an override image swatches are not shown.
     * If swatches don't exit or if there is only 1 swatch, they aren't shown
     */
    const showSwatches =
      shouldShowSwatches && !isBundle && !primaryImageOverride && swatches && swatches.size > 1
    const showSubtitle = shouldShowProductDescription
      ? descriptionOverride
        ? descriptionOverride
        : itemDetail.get("description")
      : ""
    const showFullPrice = shouldShowPrices ? `$${fullPrice}` : ""
    const showPrice = shouldShowPrices ? `$${price}` : ""
    const promoPrice = Math.round(((100 - activeSitewidePromoDiscount) / 100) * price)
    const showPromoPrice =
      shouldShowPrices && activeSitewidePromoDiscount > 0 ? `$${promoPrice}` : ""

    let primaryImage = primaryImageUrl
    let secondaryImage = secondaryImageUrl
    if (primaryImageOverride) {
      primaryImage = primaryImageOverride
      secondaryImage = secondaryImageOverride || null
    }

    return (
      <ProductTile
        className={classNames(styles.productTile, className)}
        countDetails={countDetails}
        ctaText="Quick Shop"
        isFielderProduct={isFielderProduct}
        isMobile={isSmartphone}
        isTablet={isTablet}
        isTruncateSwatches // Will override Scrolling Swatches from showing up on Mobile and show Truncated swatches instead
        subtitle={showSubtitle}
        name={itemName}
        showCTA={!isBundle && !isGiftCard}
        showSavedItemCta={false}
        showSwatches={showSwatches}
        slug={slug}
        itemKey={id}
        onCTAClick={handleQuickAddClick}
        onProductTileClick={() => {
          handleProductTileClick(slug, contentfulId)
        }}
        onSwatchClick={handleSwatchClick}
        onSwatchMouseEnter={handleSwatchMouseEnter}
        onSwatchMouseLeave={handleSwatchMouseLeave}
        swatches={swatches}
        priority={priority}
        placeholder={placeholder}
        // props below will change based on selected swatch index
        selectedSwatchIndex={selectedSwatchIndex}
        fullPrice={showFullPrice}
        price={showPrice}
        promoPrice={showPromoPrice}
        primaryImageUrl={primaryImage}
        secondaryImageUrl={secondaryImage}
        link={link}
        selectedOptions={selectedOptions}
        ariaLabel={ariaLabel}
        title={!hideTitleAndFit ? title : ""}
        promotionExclusions={promotionExclusions}
        promo={promo}
        inStock={inStock}
      />
    )
  }

  return (
    <div
      className={classNames(
        "component",
        "contentful-component",
        "contentful-product-tile",
        styles.contentfulComponent,
        !hideTitleAndFit && styles.contentfulComponentHeight
      )}
      data-sku={sku}
      data-color={color}
    >
      {itemsDetails.get(productKey) && renderProductTile()}
    </div>
  )
}
ContentfulProductTile.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  contentfulId: PropTypes.string,
  handleProductTileClick: PropTypes.func,
  handleQuickAddClick: PropTypes.func,
  handleSwatchClick: PropTypes.func,
  handleSwatchMouseEnter: PropTypes.func,
  handleSwatchMouseLeave: PropTypes.func,
  itemsDetails: ImmutablePropTypes.map,
  primaryImageOverride: PropTypes.string,
  secondaryImageOverride: PropTypes.string,
  sharedProductsData: ImmutablePropTypes.map,
  shouldShowPrices: PropTypes.bool,
  shouldShowSwatches: PropTypes.bool,
  descriptionOverride: PropTypes.string,
  sku: PropTypes.string,
  tileLoaded: PropTypes.func,
  placeholder: PropTypes.string,
  priority: PropTypes.bool,
  promotionExclusions: ImmutablePropTypes.map,
  promo: ImmutablePropTypes.map,
  hideTitleAndFit: PropTypes.bool,
  shouldShowProductDescription: PropTypes.bool,
  activeSitewidePromoDiscount: PropTypes.number,
}

ContentfulProductTile.defaultProps = {
  className: "",
  shouldShowPrices: true,
  promotionExclusions: fromJS({}),
  promo: Map(),
  hideTitleAndFit: false,
  shouldShowProductDescription: false,
  activeSitewidePromoDiscount: 0,
}

export default ContentfulProductTile
