import { connect } from "react-redux"
import { fromJS } from "immutable"
import ContentfulProductTile from "highline/components/contentful/contentful_product_tile"
import { productPreviewClicked } from "highline/redux/actions/category_actions"
import {
  contentfulProductTilePreviewClickedAsync,
  contentfulProductTileVariantSelected,
  contentfulProductTileVariantActivated,
  contentfulProductTileVariantDeactivated,
  contentfulProductTileClicked,
} from "highline/redux/actions/contentful_actions"
import { getField } from "highline/utils/contentful/contentful_helper"

const mapStateToProps = (state, ownProps) => {
  const promo = state.getIn(["activePromotion", "promo"])
  const promoDiscount = getField(promo, "discount")
  const promoCode = getField(promo, "promoCode")
  const promotionExclusionsData = state.get("promotion")

  return {
    className: ownProps.className,
    hideTitleAndFit: ownProps.hideTitleAndFit,
    cmsContent: state.getIn("rightDrawer", "cmsContent"),
    color: ownProps.color,
    contentfulId: ownProps.contentfulId,
    hideSubtitle: ownProps.hideSubtitle,
    itemsDetails: state.getIn(["contentful", "itemsDetails"]),
    onClick: ownProps.onClick,
    primaryImageOverride: ownProps.primaryImageOverride,
    secondaryImageOverride: ownProps.secondaryImageOverride,
    shouldShowSwatches: ownProps.shouldShowSwatches,
    sku: ownProps.sku,
    placeholder: ownProps.placeholder,
    priority: ownProps.priority,
    promo: promo,
    activeSitewidePromoDiscount: promoDiscount ? promoDiscount : 0,
    promotionExclusions:
      promoCode?.toLowerCase() === promotionExclusionsData.get("code")?.toLowerCase()
        ? promotionExclusionsData.get("exclusions")
        : fromJS({}),
    shouldShowProductDescription: ownProps.shouldShowProductDescription,
    descriptionOverride: ownProps.descriptionOverride,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleProductTileClick: (slug, contentfulId) => {
      dispatch(contentfulProductTileClicked(slug, contentfulId))
    },

    handleQuickAddClick: (slug, selectedOptions) => {
      dispatch(productPreviewClicked(slug, selectedOptions))
      dispatch(contentfulProductTilePreviewClickedAsync(slug))
    },

    handleSwatchClick: (slug, optionValue) => {
      dispatch(contentfulProductTileVariantSelected(slug, optionValue))
    },

    handleSwatchMouseEnter: (slug, optionValue) => {
      dispatch(contentfulProductTileVariantActivated(slug, optionValue))
    },

    handleSwatchMouseLeave: (slug) => {
      dispatch(contentfulProductTileVariantDeactivated(slug))
    },
  }
}

const ContentfulProductTileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentfulProductTile)

export default ContentfulProductTileContainer
